<template>
  <b-row>
    <b-col
      md="12"
      class="mb-1"
    >
      <b-button
        variant="primary"
        @click="$router.history.push({ name: 'add-asset' })"
      >
        Add +
      </b-button>
    </b-col>
    <b-col md="12">
      <asset-table
        :items="assets"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import * as assetService from '@/services/asset'
import AssetTable from './AssetTable.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    AssetTable,
  },
  data() {
    return {
      assets: [],
    }
  },
  created() {
    this.getAssets()
  },
  methods: {
    /** Get Table Data */
    getAssets() {
      this.$store.commit('set_loading_action', true)
      assetService.getAssets()
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.assets = res.data.assets
        })
        .catch(() => {
          this.$store.commit('set_loading_action', false)
        })
    },
  },
}
</script>

<style>

</style>
