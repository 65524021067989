<template>
  <b-card class="mb-0">
    <b-row>
      <b-col
        md="3"
        class="mb-1"
      >
        <strong>ELD Provider : </strong>{{ item.eld_provider }}
      </b-col>
      <b-col
        md="3"
        class="mb-1"
      >
        <strong>ELD Device Name : </strong>{{ item.eld_device_name }}
      </b-col>
      <b-col
        md="3"
        class="mb-1"
      >
        <strong>ELD Serial Number : </strong>{{ item.eld_serial_number }}
      </b-col>
      <b-col
        md="3"
        class="mb-1"
      >
        <strong>ELD Unit : </strong>{{ item.eld_unit }}
      </b-col>
      <b-col
        md="3"
        class="mb-1"
      >
        <strong>GPS Provider : </strong>{{ item.gps_provider }}
      </b-col>
      <b-col
        md="3"
        class="mb-1"
      >
        <strong>GPS Device Name : </strong>{{ item.gps_device_name }}
      </b-col>
      <b-col
        md="3"
        class="mb-1"
      >
        <strong>GPS Serial Number : </strong>{{ item.gps_serial_number }}
      </b-col>
      <b-col
        md="3"
        class="mb-1"
      >
        <strong>GPS Unit : </strong>{{ item.gps_unit }}
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>

<style>
   .iconType {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
</style>
