/* eslint-disable no-shadow */
import axios from 'axios'
import api from '@/utils/endpoints'
import dotenv from 'dotenv'

dotenv.config()

const headers = {
  Accept: 'application/json',
  Authorization: `Bearer ${localStorage.getItem('token')}`,
}

export const getAssets = () => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.asset.get}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const create = params => new Promise((resolve, reject) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.asset.create}`, params, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const edit = id => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.asset.edit}/${id}`, { headers })
    .then(res => {
      if (res.data.success) {
        resolve(res.data)
      }
    })
    .catch(err => {
      reject(err)
    })
})

export const update = params => new Promise((resolve, reject) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.asset.update}`, params, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const deleteAsset = id => new Promise((resolve, reject) => {
  axios
    .get(`${process.env.VUE_APP_API_URL}/${api.asset.delete}/${id}`, { headers })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
})

export const getAssetsByType = param => new Promise((resolve, reject) => {
  axios
    .post(`${process.env.VUE_APP_API_URL}/${api.asset.get}`, param, { headers })
    .then(res => {
      const equipments = res.data.assets.map(asset => ({
        id: asset.id,
        name: `${asset.type === 'Trailer' ? `${asset.trailer_type}, ${asset.trailer_size}` : `${asset.model}, ${asset.year}`}`,
        tag: asset.tag,
      }))
      resolve(equipments)
    })
    .catch(err => {
      reject(err)
    })
})
